import * as d3 from "d3";
import { color } from "d3";
import { entries } from "lodash";
import React, { useRef, useEffect, useState } from "react";
import * as topojson from "topojson-client";

const Choropleth = (props) => {
  const { width } = props;
  const { height } = props;
  const { data } = props;
  const { onClick } = props;

  const svgRef = useRef();
  const gRef = useRef();
  const legendRef = useRef();

  const [loadingTopo, setLoadingTopo] = useState([true]);
  const [us, setUs] = useState([]);
  const [states, setStates] = useState([]);
  const [stateTopo, setStateTopo] = useState([]);
  const [countyTopo, setCountyTopo] = useState([]);

  useEffect(() => {
    getTopoData();
  }, []);

  useEffect(() => {
    if (!loadingTopo) {
      draw();
    }
  }, [loadingTopo]);

  const getTopoData = () => {
    d3.json("/data/counties-albers-10m.json").then((d) => {
      setUs(d);
      setStates(
        new Map(d.objects.states.geometries.map((d) => [d.id, d.properties]))
      );
      setStateTopo(topojson.feature(d, d.objects.states).features);
      setCountyTopo(topojson.feature(d, d.objects.counties).features);
      setLoadingTopo(false);
    });
  };

  function legendMaker(g, color, title) {
    const x = d3
      .scaleLinear()
      .domain(d3.extent(color.domain()))
      .rangeRound([0, 400]);

    g.selectAll("rect")
      .data(color.range().map((d) => color.invertExtent(d)))
      .join("rect")
      .attr("height", 8)
      .attr("x", (d) => x(d[0]))
      .attr("width", (d) => x(d[1]) - x(d[0]))
      .attr("fill", (d) => color(d[0]));

    g.append("text")
      .attr("x", x.range()[0])
      .attr("y", -6)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .attr("font-weight", "bold")
      .text(title);

    g.call(d3.axisBottom(x).tickValues(getTickValues(color)))
      .select(".domain")
      .remove();
  }

  function getTickValues(color) {
    const tickValues = color.range().map((d) => color.invertExtent(d)[0]);
    const lastColor = color.range().slice(-1)[0];
    const lastTick = color.invertExtent(lastColor)[1];
    tickValues.push(lastTick);
    return tickValues;
  }

  function reset() {
    const t = d3.transition().duration(800);

    d3.selectAll(".state")
      .transition(t)
      .attr("d", path)
      .attr("fill", (d) =>
        color((stateData.find((el) => el.id === d.id) || {}).count)
      );

    d3.selectAll(".county")
      .data([])
      .join((exit) =>
        exit.call((exit) =>
          exit.transition(t).attr("d", path).attr("opacity", 0).remove()
        )
      );

    svg
      .transition(t)
      .call(
        zoom.transform,
        d3.zoomIdentity,
        d3.zoomTransform(svg.node()).invert([width / 4, height / 4])
      );

    legend.transition(t).attr("opacity", 1);

    //call back to reset the line graph
    onClick({ id: "all", name: "All States" });
  }

  var zoom = d3.zoom().scaleExtent([1, 8]).on("zoom", zoomed);

  function clicked(event, d) {
    event.stopPropagation();

    const stateCounties = countyTopo.filter((el) => {
      return el.id.substring(0, 2) === d.id;
    });
    //console.log(d);
    const countyData = data.filter((el) => el.id.substring(0, 2) === d.id);

    const t = d3.transition().duration(800);

    const [[x0, y0], [x1, y1]] = path.bounds(d);

    const countyColor = d3.scaleQuantize(
      d3.extent(countyData, (d) => d.count),
      d3.schemeBlues[9]
    );

    svg
      .transition()
      .duration(750)
      .call(
        zoom.transform,
        d3.zoomIdentity
          .translate(width / 2, height / 2)
          .scale(
            Math.min(8, 0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height))
          )
          .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
        d3.pointer(event, svg.node())
      );

    const countyPaths = g
      .selectAll(".county")
      .data(stateCounties, (d) => d.id)
      .join(
        (enter) =>
          enter
            .append("path")
            .classed("county", true)
            .attr("d", path)
            .attr("fill", (d) =>
              countyColor((data.find((el) => el.id === d.id) || {}).count)
            )
            .attr("opacity", 0)
            .on("click", reset)
            .call((enter) =>
              enter.transition(t).attr("d", path).attr("opacity", 1)
            )
            .append("title")
            .text(
              (d) =>
                `${
                  countyTopo.find((el) => el.id === d.id).properties.name
                } ${d3.format(",.0f")(
                  (data.find((el) => el.id === d.id) || {}).count
                )}`
            ),
        (exit) =>
          exit.call((exit) =>
            exit.transition(t).attr("d", path).attr("opacity", 0).remove()
          )
      );

    d3.selectAll(".state").transition(t).attr("d", path).attr("fill", "#444");

    legend.transition(t).attr("opacity", 0);
  }

  function zoomed(event) {
    const { transform } = event;
    g.attr("transform", transform);
    g.attr("stroke-width", 1 / transform.k);
  }

  let stateData = data.reduce((acc, val) => {
    //this will aggregate the counties to their respective states and sum count
    if (!acc.find((el) => el.id === val.state.toString().padStart(2, "0"))) {
      acc.push({
        id: val.state.toString().padStart(2, "0"),
        count: 0,
      });
    }
    let getState = acc.find((s) => {
      return s.id === val.state.toString().padStart(2, "0");
    });
    getState.count += val.count;
    return acc;
  }, []);

  const color = d3.scaleQuantize(
    d3.extent(stateData, (d) => d.count),
    d3.schemeBlues[9]
  );

  const path = d3.geoPath();

  const handleClick = (event, d) => {
    onClick({ id: d.id, name: d.properties.name });
    clicked(event, d);
  };

  const svg = d3
    .select(svgRef.current)
    .attr("viewBox", `0 0 ${width} ${height}`)
    .on("click", reset);

  const g = d3.select(gRef.current);

  const legend = d3
    .select(legendRef.current)
    .attr("transform", "translate(450,40)");

  function draw() {
    const statePaths = g
      .append("g")
      .selectAll(".state")
      .data(stateTopo)
      .join("path")
      .classed("state", true)
      .attr("fill", (d) =>
        color((stateData.find((el) => el.id === d.id) || {}).count)
      )
      .attr("d", path)
      .on("click", handleClick)
      .append("title")
      .text(
        (d) =>
          `${d.properties.name} ${d3.format(",.0f")(
            (stateData.find((el) => el.id === d.id) || {}).count
          )}`
      );

    g.append("path")
      .datum(topojson.mesh(us, us.objects.states, (a, b) => a !== b))
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-linejoin", "round")
      .attr("d", path);

    svg.call(zoom);

    legend.call(legendMaker, color, "Fatal Accidents");
  }

  return (
    <div className="choropleth">
      <h2>Where did the fatal accidents occur?</h2>
      <svg ref={svgRef}>
        <g ref={gRef}></g>
        <g ref={legendRef}></g>
      </svg>
    </div>
  );
};

export default Choropleth;
