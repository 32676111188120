import * as d3 from "d3";
import React, { useRef } from "react";
import SwatchLegend from "./SwatchLegend";

function StreamGraph(props) {
  const width = props.width;
  const height = props.height;
  const data = Object.assign(
    props.data,
    { columns: Object.keys(props.data[1]) },
    { y: "Causal Factor" }
  );

  const ref = useRef();

  const margin = { top: 20, right: 30, bottom: 50, left: 20 };

  const series = d3
    .stack()
    .keys(data.columns.slice(1))
    .offset(d3.stackOffsetWiggle)
    .order(d3.stackOrderInsideOut)(data);

  const area = d3
    .area()
    .x((d) => x(d.data.year))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]));

  const x = d3
    .scaleLinear()
    .domain(d3.extent(data, (d) => d.year))
    .range([margin.left, width - margin.right]);

  const y = d3
    .scaleLinear()
    .domain([
      d3.min(series, (d) => d3.min(d, (d) => d[0])),
      d3.max(series, (d) => d3.max(d, (d) => d[1])),
    ])
    .range([height - margin.bottom, margin.top]);

  const xAxis = (g) =>
    g
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
          .tickFormat(d3.format("d"))
      )
      .call((g) => g.select(".domain").remove());

  const color = d3
    .scaleOrdinal()
    .domain(data.columns.slice(1))
    .range(d3.schemePaired);

  const svg = d3.select(ref.current).attr("viewBox", [0, 0, width, height]);

  svg
    .append("g")
    .selectAll("path")
    .data(series)
    .join("path")
    .attr("fill", ({ key }) => color(key))
    .attr("d", area)
    .append("title")
    .text(({ key }) => key);

  svg.append("g").call(xAxis);

  return (
    <div className="streamGraph">
      <h2>Comparison of Some Causal Factors of Fatal Accidents 2003 - 2019</h2>
      <SwatchLegend color={color} columns={180} marginLeft={50} />
      <svg ref={ref}></svg>
    </div>
  );
}

export default StreamGraph;
