import * as d3 from "d3";
import { svg } from "d3";
import React, { useRef, useEffect, useState } from "react";

function LineGraph(props) {
  const { width, height, data } = props;

  const ref = useRef();

  useEffect(() => {
    setScales();
    draw();
  }, [data]);

  const margin = { top: 20, right: 30, bottom: 50, left: 70 };

  var x = d3.scaleLinear().range([margin.left, width - margin.right]);

  var y = d3.scaleLinear().range([height - margin.bottom, margin.top]);

  const line = d3
    .line()
    .curve(d3.curveNatural)
    .defined((d) => !isNaN(d.count))
    .x((d) => x(d.year))
    .y((d) => y(d.count));

  var bisectDate = d3.bisector((d) => d.year).left;

  function mousemove(event) {
    var x0 = x.invert(d3.pointer(event, this)[0]);
    var i = bisectDate(data, x0, 1);
    var d0 = data[i - 1];
    var d1 = data[i];
    return d1 && x0 - d0.year > d1.year - x0 ? d1 : d0;
  }

  const callout = (g, value) => {
    if (!value) return g.style("display", "none");
    g.style("display", null)
      .style("pointer-events", "none")
      .style("font", "10px sans-serif");

    const path = g
      .selectAll("path")
      .data([null])
      .join("path")
      .attr("fill", "white")
      .attr("stroke", "black");

    const text = g
      .selectAll("text")
      .data([null])
      .join("text")
      .call((text) =>
        text
          .selectAll("tspan")
          .data((value + "").split(/\n/))
          .join("tspan")
          .attr("x", 0)
          .attr("y", (d, i) => `${i * 1.1}em`)
          .style("font-weight", (_, i) => (i ? null : "bold"))
          .text((d) => d)
      );

    const { x, y, width: w, height: h } = text.node().getBBox();

    text.attr("transform", `translate(${-w / 2},${15 - y})`);
    path.attr(
      "d",
      `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`
    );
  };

  function setScales() {
    x.domain(d3.extent(data, (d) => d.year));

    y.domain([0, d3.max(data, (d) => d.count)]).nice();

    //var svgTransition = d3.select(ref.current).transition();

    d3.select("#yAxis").remove();
  }

  function draw() {
    const xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
          .tickFormat(d3.format("d"))
      );

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left}, 0)`)
        .classed("yAxis", true)
        .call(d3.axisLeft(y))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .select(".tick:last-of-type text")
            .clone()
            .attr("x", 3)
            .attr("text-anchor", "start")
            .attr("font-weight", "bold")
            .text(data.y)
        );

    let svg = d3.select(ref.current).attr("viewBox", `0 0 ${width} ${height}`);

    svg.append("g").call(xAxis);

    svg
      .append("text")
      .attr(
        "transform",
        "translate(" + width / 2 + " ," + (height - margin.top + 10) + ")"
      )
      .style("text-anchor", "middle")
      .style("font-size", "0.8em")
      .text("Year");

    svg.append("g").attr("id", "yAxis").call(yAxis);

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 5)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", "0.8em")
      .text("Number of Fatal Accidents");

    /*svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("stroke-linejoin", "round")
      .attr("d", line);*/

    svg
      .selectAll(".line")
      .data([data])
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .classed("line", true),
        (update) => update,
        (exit) => exit.remove()
      )
      .attr("d", line);

    const tooltip = svg.append("g");

    svg.on("touchmove mousemove", function (event) {
      const { year, count } = mousemove(event);

      tooltip.attr("transform", `translate(${x(year)},${y(count)})`).call(
        callout,
        `${count}
        ${year}`
      );
    });

    //svg.on("touchmove mousemove", mousemove);

    svg.on("touchend mouseleave", () => tooltip.call(callout, null));
  }

  return (
    <div className="lineGraph">
      <h2 className="lineGraphTitle">
        Fatal Accidents: {props.selectedState.name}
      </h2>
      <svg ref={ref}></svg>
    </div>
  );
}

LineGraph.defaultProps = {
  selectedState: "All States",
};

export default LineGraph;
