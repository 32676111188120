import React from "react";

function LineGraphDetails() {
  return (
    <div id="lineGraphDetails">
      <p>
        Hover over the line graph to see more information. The graph will change
        when a state is selected from the map.
      </p>
    </div>
  );
}

export default LineGraphDetails;
