import React, { useEffect, useState } from "react";

import "./App.css";
import loader from "./loader.gif";

import LineGraph from "./components/LineGraph";
import Choropleth from "./components/Choropleth";
import LineGraphDetails from "./components/LineGraphDetails";
import ChoroplethDetails from "./components/ChoroplethDetails";
import StreamGraph from "./components/StreamGraph";
import Treemap from "./components/Treemap";

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([true]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [choroplethData, setChoroplethData] = useState([]);
  const [streamData, setStreamData] = useState([]);
  const [loadingStream, setLoadingStream] = useState([true]);
  const [selectedState, setSelectedState] = useState({
    id: "all",
    name: "All States",
  });
  const [treeData, setTreeData] = useState([]);
  const [loadingTree, setLoadingTree] = useState([true]);

  useEffect(() => {
    getData();
  }, [loading]);

  useEffect(() => {
    lineData();
  }, [loading, selectedState]);

  useEffect(() => {
    choroData();
  }, [loading]);

  useEffect(() => {
    streamDataLoad();
  }, [loadingStream]);

  useEffect(() => {
    treeDataLoad();
  }, [loadingTree]);

  const getData = () => {
    fetch("/data/accidents_by_year.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        setData(jsonData);
        setLoading(false);
      });
  };

  const lineData = () => {
    /*this will prepare the data for the line graph
    //all we need is year and total for the year depending on
    //what state is clicked or all states, default is all states
    */
    var years = [];
    let dataHolder = data;
    if (selectedState.id !== "all") {
      //filter for the state
      dataHolder = data.filter((el) => {
        return el.state == selectedState.id;
      });
    }
    years = dataHolder.reduce(function (acc, val) {
      if (!acc.find(({ year }) => year === val.year)) {
        acc.push({ year: val.year, count: 0 });
      }
      let getYear = acc.find((y) => {
        return y.year === val.year;
      });
      getYear.count += val.count;
      return acc;
    }, []);
    years.sort((a, b) => a.year - b.year);
    setLineGraphData(years);
  };

  const choroData = () => {
    /*this will prepare the data for the choropleth
    //this will prepare an array of objects with state
    //and accidents counts for that state
    */
    var counties = [];
    counties = data.reduce(function (acc, val) {
      if (
        !acc.find((el) => el.state === val.state && el.county === val.county) //&&
      ) {
        acc.push({
          id:
            val.state.toString().padStart(2, "0") +
            val.county.toString().padStart(3, "0"),
          state: val.state,
          county: val.county,
          count: 0,
        });
      }

      const getCounty = acc.find((c) => {
        return c.state === val.state && c.county === val.county;
      });
      getCounty.count += val.count;
      return acc;
    }, []);
    setChoroplethData(counties);
  };

  const streamDataLoad = () => {
    fetch("/data/stream.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        setStreamData(jsonData);
        setLoadingStream(false);
      });
  };

  const treeDataLoad = () => {
    fetch("/data/tree.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        setTreeData(jsonData);
        setLoadingTree(false);
      });
  };

  const handleStateChange = (clickedState) => {
    setSelectedState(clickedState);
  };

  return (
    <div className="App">
      {loading && (
        <div>
          loading <img src={loader} alt="Loading" />
        </div>
      )}
      {!loading && lineGraphData.length > 0 && (
        <LineGraph
          width={400}
          height={300}
          data={lineGraphData}
          selectedState={selectedState}
        />
      )}
      {!loading && choroplethData.length > 0 && (
        <Choropleth
          width={975}
          height={610}
          data={choroplethData}
          onClick={handleStateChange}
        />
      )}
      <LineGraphDetails />
      <ChoroplethDetails />
      <p className="summary">
        Overall there is a slight decrease in the number of fatal accidents. We
        can see from the map that the more populous states, like CA, TX and FL,
        have the highest number of fatal accdients. This trend of more populous
        areas holds when zooming in to the state level and observing the county
        details. When zooming into the states we can see that some states, like
        IN, have an overall decreasing trend in the number of fatal accidents.
        However, some states, like NV, have an overall increaseing trend in the
        number of fatal accidents.
      </p>
      {loadingStream && <div>loading</div>}
      {!loadingStream && streamData.length > 0 && (
        <StreamGraph width={500} height={300} data={streamData} />
      )}
      <p className="summary">
        The stream graph shows us that speeding and DUI are the more significant
        causes of fatal accidents from the selected causal categories.
        Distracted driving includes mobile device usage. It is good to see that
        the distracted driving causal factor has decreased from the early
        2000's.
      </p>
      {loadingTree && <div>loading</div>}
      {!loadingTree && treeData && (
        <Treemap width={500} height={400} data={treeData} />
      )}
      <p className="summary">
        Clicking on the darker blue regions allows for drilling down into the
        grouping. Click on the light blue bar at the top to return to the top
        level of the tree map. This visualization allows for comparing different
        vehicle manufacturers and the number of fatalities associated with
        accidents from 1991 to 2019. For the top three American manufacturers we
        can see that the Light Trucks category has the highest number of
        fatalities. This might be correlated to the popularity of light trucks
        in the United States over the past three decades. Or perhaps further
        research could reveal a hidden danger associated with the light trucks
        on our roadways.
      </p>
    </div>
  );
}

export default App;
