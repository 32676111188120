import React from "react";

function Swatch(props) {
  const swatchWidth = props.swatchWidth;
  const swatchHeight = props.swatchHeight;
  const color = props.color;
  const label = props.label;

  const swatchStyle = {
    backgroundColor: color,
    width: +swatchWidth,
    height: +swatchHeight,
  };

  const labelStyle = {
    maxWidth: `calc(100% - ${swatchWidth}px - 0.5em)`,
  };

  return (
    <div className="swatchItem">
      <div className="swatch" style={swatchStyle}></div>
      <div className="swatchLabel" style={labelStyle}>
        {label}
      </div>
    </div>
  );
}

export default Swatch;
