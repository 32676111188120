import React from "react";

function ChoroplethDetails() {
  return (
    <div id="choroplethDetails">
      <p>
        Click on a state to zoom in and see the county level details. Hover over
        a state or county to see more information. Click on the county to reset
        the zoom.
      </p>
    </div>
  );
}

export default ChoroplethDetails;
