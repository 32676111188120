import React from "react";

import Swatch from "./Swatch";

function SwatchLegend(props) {
  const color = props.color;
  const format = props.format;
  const swatchSize = props.swatchSize;
  const swatchWidth = props.swatchWidth || props.swatchSize;
  const swatchHeight = props.swatchHeight || props.swatchSize;
  const marginLeft = props.marginLeft;
  const columns = props.columns;

  //styles for the swatch legend
  const swatchDiv = {
    marginLeft: +marginLeft,
  };

  const swatchWrapper = {
    columns: columns + "px auto",
  };

  return (
    <div className="swatchDiv" style={swatchDiv}>
      <div className="swatchWrapper" style={swatchWrapper}>
        {color.domain().map((c) => {
          return (
            <Swatch
              key={c}
              swatchWidth={swatchWidth}
              swatchHeight={swatchHeight}
              label={format(c)}
              color={color(c)}
            />
          );
        })}
      </div>
    </div>
  );
}

SwatchLegend.defaultProps = {
  color: "red",
  format: (x) => x,
  swatchSize: 15,
  marginLeft: 0,
  columns: 180,
};

export default SwatchLegend;
